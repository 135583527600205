import { pathOr } from 'ramda';
import React, { Suspense, lazy } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LeadRoute, OpenRoute,AdminRoute,CommonRoute,AgentRoute,CommonLeadRoute } from '../components/ProtectedRoute';
import PF from '../container/PF';

const Login = lazy(() => import('../container/Login'));
const OTP = lazy(() => import('../container/OTP'));
const Home = lazy(() => import('../container/Home'));
const AddLead = lazy(() => import('../container/AddLead'));
const EditLead = lazy(() => import('../container/EditLead'));
const ViewLead = lazy(() => import('../container/ViewLead'));
const Leads = lazy(() => import('../container/Leads'));
const Loan = lazy(() => import('../container/Loan'));
const Assignment = lazy(() => import('../container/Assignment'));
const Policy = lazy(() => import('../container/Policy'));
const Rejected = lazy(() => import('../container/Rejected'));
const ChangePassword = lazy(() => import('../container/ChangePassword'));
const AdminHome = lazy(() => import('../container/AdminHome'));
const Upload = lazy(() => import('../container/upload'));
const AgentHome = lazy(()=> import('../container/AgentHome'))

const Navigation = () => {
    return (
        <Router>
            <Suspense fallback={<div><LinearProgress /></div>}>
                <Routes>
                    <Route path="/" element={<OpenRoute><Login /></OpenRoute>} />
                    <Route path="/login" element={<OpenRoute><Login /></OpenRoute>} />
                    <Route path="/otp" element={<OpenRoute><OTP /></OpenRoute>} />
                    <Route path="/home" element={<OpenRoute><Home /></OpenRoute>}/>
                    <Route path="/leads" element={<OpenRoute><Leads /></OpenRoute>}/>
                    <Route path="/loan" element={<OpenRoute><Loan /></OpenRoute>}/>
                    <Route path="/pf" element={<OpenRoute><PF /></OpenRoute>}/>
                    <Route path="/policy" element={<CommonRoute><Policy /></CommonRoute>}/>
                    <Route path="/assignment" element={<OpenRoute><Assignment /></OpenRoute>}/>
                    <Route path="/addlead" element={<OpenRoute><AddLead /></OpenRoute>}/>
                    <Route path="/changepassword" element={<CommonLeadRoute><ChangePassword /></CommonLeadRoute>}/>
                    <Route path="/rejected" element={<CommonRoute><Rejected /></CommonRoute> } />
                    <Route path="/editlead/:id" element={<LeadRoute><EditLead /></LeadRoute>} />
                    <Route path="/viewlead/:id" element={<CommonRoute><ViewLead /></CommonRoute>} />
                    <Route path="/adminhome" element={<AdminRoute><AdminHome /></AdminRoute> } />
                    <Route path="/upload" element={<AdminRoute><Upload /></AdminRoute> } />
                    <Route path="/agenthome" element={<AgentRoute><AgentHome /></AgentRoute> } />
                </Routes>
            </Suspense>
        </Router>
    )
}
export default Navigation
