import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/People';
import PeopleIcon from '@mui/icons-material/CreditScore';
import BarChartIcon from '@mui/icons-material/Assignment';
import LayersIcon from '@mui/icons-material/LocalPolice';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import LockResetIcon from '@mui/icons-material/LockReset';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';

const CustomLink = (props) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return(
    <div style={{backgroundColor: match ? "#2BAF68" : '#0D974D'}}>
      <Link
        style={{ textDecoration: match ? "none" : "none", color:'white' }}
        to={props.to}
        {...props}
      >
        {props.children}
      </Link>
    </div>
  )
}

export const mainListItems = (
  <React.Fragment>
    <CustomLink to='/home'>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon style={{ color: "#fac42b" }}/>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
    </CustomLink>
    {/* <CustomLink to='/upload'>
      <ListItemButton>
        <ListItemIcon>
          <UploadFileIcon  style={{ color: "#fac42b" }}/>
        </ListItemIcon>
        <ListItemText primary="Upload" />
      </ListItemButton>
    </CustomLink> 
    <CustomLink to='/leads'>
    <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Leads" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/pf'>
    <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Premium Financing" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/loan'>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Loan" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/assignment'>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Assignment" />
    </ListItemButton>
    </CustomLink>*/}
    {/* <CustomLink to='/policy'>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Retained" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/rejected'>
    <ListItemButton>
      <ListItemIcon>
        <ThumbDownIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Rejected" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/changepassword'>
    <ListItemButton>
      <ListItemIcon>
        <LockResetIcon  style={{ color: "#fac42b" }}/>
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </ListItemButton>
    </CustomLink> */}
  </React.Fragment>
);
export const adminmainListItems = (
  <React.Fragment>
    <CustomLink to='/home'>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon  style={{ color: "#fff" }}/>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
    </CustomLink>
    {/* <CustomLink to='/upload'>
      <ListItemButton>
        <ListItemIcon>
          <UploadFileIcon  style={{ color: "#fac42b" }}/>
        </ListItemIcon>
        <ListItemText primary="Upload" />
      </ListItemButton>
    </CustomLink>  */}
    <CustomLink to='/leads'>
    <ListItemButton>
      <ListItemIcon>
        <ShoppingCartIcon  style={{ color: "#fff" }}/>
      </ListItemIcon>
      <ListItemText primary="Leads" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/loan'>
    <ListItemButton>
      <ListItemIcon>
        <PeopleIcon  style={{ color: "#fff" }}/>
      </ListItemIcon>
      <ListItemText primary="Loan" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/assignment'>
    <ListItemButton>
      <ListItemIcon>
        <BarChartIcon  style={{ color: "#fff" }}/>
      </ListItemIcon>
      <ListItemText primary="Assignment" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/policy'>
    <ListItemButton>
      <ListItemIcon>
        <LayersIcon  style={{ color: "#fff" }}/>
      </ListItemIcon>
      <ListItemText primary="Retained" />
    </ListItemButton>
    </CustomLink>
    <CustomLink to='/rejected'>
    <ListItemButton>
      <ListItemIcon>
        <ThumbDownIcon  style={{ color: "#fff" }}/>
      </ListItemIcon>
      <ListItemText primary="Rejected" />
    </ListItemButton>
    </CustomLink>
  </React.Fragment>
);
export const agentmainListItems = (
  <React.Fragment>
    <CustomLink to='/agenthome'>
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon  style={{ color: "#fff" }}/>
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
    </CustomLink>
    <CustomLink to='/changepassword'>
    <ListItemButton>
      <ListItemIcon>
        <LockResetIcon  style={{ color: "#fff" }}/>
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </ListItemButton>
    </CustomLink>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Saved reports
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItemButton>
  </React.Fragment>
);
