import swal from 'sweetalert'
import Api from '../../lib/api'
import {
  ADD_LEAD, GET_INSURANCE_PLANS, GET_INSURANCE_PLANS_FAILURE, ADD_LEAD_FAILURE, EDIT_LEAD, EDIT_LEAD_FAILURE,
  GET_LEAD, GET_LEAD_FAILURE, DELETE_LEAD, DELETE_LEAD_FAILURE, GET_POLICY_BY_ID, GET_POLICY_BY_ID_FAILURE,
  GET_LEAD_COUNT, GET_LEAD_COUNT_FAILURE, GET_UPLOAD_HISTORY, GET_UPLOAD_HISTORY_FAILURE, DOWNLOAD_DOCS_SUCCESS, FETCH_DISPOSITION
} from './types'
import { NotificationManager } from 'react-notifications'
export const addLead = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead')
    if (policy.status === 'success') {
      dispatch({
        type: ADD_LEAD,
        payload: policy.data
      })
      swal({
        title: 'Done!',
        text: 'Lead added successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      dispatch({
        type: ADD_LEAD_FAILURE,
        payload: policy.data
      })
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: ADD_LEAD_FAILURE,
      payload: e.message
    })
    return false;
  }
}
export const deleteLead = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/deleteLead')
    if (policy.status === 'success') {
      dispatch({
        type: DELETE_LEAD,
        payload: policy.data
      })
      swal({
        title: 'Done!',
        text: 'Lead deleted successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      dispatch({
        type: DELETE_LEAD_FAILURE,
        payload: policy.data
      })
      return false;
    }
  } catch (e) {
    dispatch({
      type: DELETE_LEAD_FAILURE,
      payload: e.message
    })
    return false;
  }
}
export const updateLeadStatus = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/updateStatus')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Lead status updated successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: DELETE_LEAD_FAILURE,
      payload: e.message
    })
    return false;
  }
}

export const updateAssignmentStatus = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/updateAssignmentStatus')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Assignment status updated successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    // dispatch({
    //   type: DELETE_LEAD_FAILURE,
    //   payload: e.message
    // })
    return false;
  }
}

export const livelinessRemainder = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/livelinessremainder')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Remainder sent successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    // dispatch({
    //   type: DELETE_LEAD_FAILURE,
    //   payload: e.message
    // })
    return false;
  }
}

export const uploadPolicyDocument = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/uploadPolicyDocument')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Assignment transfer document uploaded successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    // dispatch({
    //   type: DELETE_LEAD_FAILURE,
    //   payload: e.message
    // })
    return false;
  }
}
export const uploadPolicyDetailDocument = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/readPolicyDetailDocument')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Document imported successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      dispatch(getUploadHistory({ filter: "All" }))

      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    return false;
  }
}
export const updateMoneyDispursedStatus = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/updateMoneyDispursedStatus')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Money disbursed data updated successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    // dispatch({
    //   type: DELETE_LEAD_FAILURE,
    //   payload: e.message
    // })
    return false;
  }
}
export const updatePendingPaymentStatus = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/updatePendingPaymentStatus')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Pending payment data updated successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    // dispatch({
    //   type: DELETE_LEAD_FAILURE,
    //   payload: e.message
    // })
    return false;
  }
}
export const updateLoanStatus = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/updateLoanStatus')
    if (policy.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Loan status updated successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      return true;
    } else {
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
      return false;
    }
  } catch (e) {
    // dispatch({
    //   type: DELETE_LEAD_FAILURE,
    //   payload: e.message
    // })
    return false;
  }
}

export const editLead = (policy) => async (dispatch) => {
  const body = policy
  try {
    const policy = await Api.post(body, '/lead/editLead')
    if (policy.status === 'success') {
      dispatch({
        type: EDIT_LEAD,
        payload: policy.data
      })
      swal({
        title: 'Done!',
        text: 'Lead edited successfully',
        icon: 'success',
        timer: 3000,
        button: false
      })
      dispatch(getLead({ filter: "All" }))
      return true;
    } else {
      dispatch({
        type: EDIT_LEAD_FAILURE,
        payload: policy.data
      })
      swal({
        title: 'Error',
        text: policy.data.error || policy.data.message,
        icon: 'error',
        timer: 3000,
        button: false
      })
    }
  } catch (e) {
    dispatch({
      type: EDIT_LEAD_FAILURE,
      payload: e.message
    })
  }
}

export const getLead = (filter) => async (dispatch) => {
  const body = filter
  try {
    const policy = await Api.post(body, '/lead/all')
    if (policy.status === 'success') {
      dispatch({
        type: GET_LEAD,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_LEAD_FAILURE,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_LEAD_FAILURE,
      payload: e.message
    })
  }
}
export const getLeads = (filter) => async (dispatch) => {
  const body = filter
  try {
    const policy = await Api.post(body, '/lead/getLeadDataWithFilters')
    if (policy.status === 'success') {
      dispatch({
        type: GET_LEAD,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_LEAD_FAILURE,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_LEAD_FAILURE,
      payload: e.message
    })
  }
}

export const getUploadHistory = (filter) => async (dispatch) => {
  const body = filter
  try {
    const policy = await Api.post(body, '/lead/getUploadHistoryData')
    if (policy.status === 'success') {
      dispatch({
        type: GET_UPLOAD_HISTORY,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_UPLOAD_HISTORY,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_UPLOAD_HISTORY_FAILURE,
      payload: e.message
    })
  }
}

export const getAssignLead = (filter) => async (dispatch) => {
  const body = filter
  try {
    const policy = await Api.post(body, '/lead/getGridDataAssignWithFilter')
    if (policy.status === 'success') {
      dispatch({
        type: GET_LEAD,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_LEAD_FAILURE,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_LEAD_FAILURE,
      payload: e.message
    })
  }
}
export const getLoanLead = (filter) => async (dispatch) => {
  const body = filter
  try {
    const policy = await Api.post(body, '/lead/getGridDataLoanWithFilter')
    if (policy.status === 'success') {
      dispatch({
        type: GET_LEAD,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_LEAD_FAILURE,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_LEAD_FAILURE,
      payload: e.message
    })
  }
}
export const getLeadCount = (filter) => async (dispatch) => {
  const body = filter
  try {
    const policy = await Api.get('/lead/stats')
    if (policy.status === 'success') {
      dispatch({
        type: GET_LEAD_COUNT,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_LEAD_COUNT_FAILURE,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_LEAD_FAILURE,
      payload: e.message
    })
  }
}
export const getLeadById = (id) => async (dispatch) => {
  const body = id
  try {
    const policy = await Api.post(body, '/policy')
    if (policy.status === 'success') {
      dispatch({
        type: GET_POLICY_BY_ID,
        payload: policy.data
      })
    } else {
      dispatch({
        type: GET_POLICY_BY_ID_FAILURE,
        payload: policy.data
      })
    }
  } catch (e) {
    dispatch({
      type: GET_POLICY_BY_ID_FAILURE,
      payload: e.message
    })
  }
}

export const getPlans = () => async dispatch => {
  try {
    const insurance = await Api.get('/lead/plans')
    if (insurance.status === 'success') {
      dispatch({
        type: GET_INSURANCE_PLANS,
        payload: insurance.data
      })
    } else {
      dispatch({
        type: GET_INSURANCE_PLANS_FAILURE,
        payload: insurance.error
      })
    }
  } catch (e) {
    dispatch({
      type: GET_INSURANCE_PLANS_FAILURE,
      payload: e.message
    })
  }
}

export const downloadDocuments = (body) => async dispatch => {
  try {
    const location = await Api.post(body, '/admin/kyc/download')
    if (location.status === 'success') {
      return location.data
    }
  } catch (e) {
    dispatch({
      type: DOWNLOAD_DOCS_SUCCESS,
      payload: e.message
    })
  }
}

export const shareLink = (body) => async dispatch => {
  try {
    const location = await Api.post(body, '/lead/link')
    if (location.status === 'success') {
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export const fetchDisposition = (body) => async dispatch => {
  try {
    const disposition = await Api.post(body, '/disposition/all')
    if (disposition.status === 'success') {
      dispatch({
        type: FETCH_DISPOSITION,
        payload: disposition.data
      })
      return true
    }
    return false
  } catch (e) {
    return false
  }
}

export const addDisposition = (body) => async dispatch => {
  try {
    const disposition = await Api.post(body, '/disposition')
    if (disposition.status === 'success') {
      swal({
        title: 'Done!',
        text: 'Remarks added successfully',
        icon: 'success',
        timer: 3000,
        button: false
      });
      dispatch(fetchDisposition(body))
      return true
    }
    return false
  } catch (e) {
    return false
  }
}