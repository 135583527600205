import {GET_NOTIFICATION_SUCCESS} from '../actions/types';

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_SUCCESS:
      return Object.assign({}, state, {
        notifications: action.payload,
      });
    default:
      return state;
  }
};

export default notificationReducer;
