import Api from '../../lib/api';
import {
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_SUCCESS,
} from './types';

export const getNotifications = () => async dispatch => {
  try {
    const notification = await Api.get('/lead/notification');
    if (notification.status === 'success') {
      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: notification.data,
      });
    } else {
      dispatch({
        type: GET_NOTIFICATION_FAILURE,
        payload: notification.error,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_NOTIFICATION_FAILURE,
      payload: e.message,
    });
  }
};

export const updateStatus = () => async dispatch => {
    try {
      const notification = await Api.get('/lead/notification/updatestatus');
      if (notification.status === 'success') {
        dispatch(getNotifications())
      }
    } catch (e) {
      dispatch({
        type: GET_NOTIFICATION_FAILURE,
        payload: e.message,
      });
    }
  };

