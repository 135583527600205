import { combineReducers } from 'redux'
import authReducer from './authReducer'
import userPreferenceReducer from './userPreferenceReducer'
import leadReducer from './leadReducer'
import notificationReducer from './notificationReducer'
import loaderReducer from './loaderReducer'

const rootReducer = combineReducers({
  Auth: authReducer,
  UserPreference: userPreferenceReducer,
  Lead: leadReducer,
  Notification:notificationReducer,
  Loader: loaderReducer,
})

export default rootReducer
