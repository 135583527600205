import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems,adminmainListItems,agentmainListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Title from './Title';
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { LOGOUT } from '../../redux/actions/types'
import MenuItem from '@mui/material/MenuItem';
import { getNotifications, updateStatus } from '../../redux/actions/notificationAction';
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import '../../container/Home/dashboard.css'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.briezi.com/">
        BRIEZI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

const Dashboard=(props)=> {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(true);
  React.useEffect(() => {
    dispatch(getNotifications())
  },[dispatch])
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const firstName = useSelector(state => pathOr('', ['firstName'], state.Auth))
  const role = useSelector(state => pathOr([], ['Auth', 'role'], state))
  const notifications = useSelector(state => pathOr([], ['Notification', 'notifications'], state))
  const isBrieziAdmin = !!role.includes('BRIEZIADMIN')
  const isLeadAgent = !!role.includes('MAXLIFEAGENT')
  const isLeadAdmin = !!role.includes('MAXLIFEADMIN')

  const handleOpenUserMenu = (event) => {
    dispatch(updateStatus())
    setAnchorElUser(event.currentTarget);
  };
  const handleNavigartion = (val) => {
    setAnchorElUser(null);
    if(val!=""){
      navigate('/viewlead/'+val,{ replace: true })
    }
    
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  
  const getNotiType=(val)=>{
    if(val.indexOf('Approved') > -1){
      return "NotificApproved";
    }else if(val.indexOf('Rejected') > -1){
      return "NotificRejected";
    }else if(val.indexOf('status updated') > -1){
      return "NotificStatus";
    }else{
      return "NotificDefault"
    }
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px',backgroundColor:'#ffffff' // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),color:'#000000'
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              color="#151515"
              noWrap
              sx={{ flexGrow: 1,textAlign:'left' }}
            >
              Hello {(isBrieziAdmin || isLeadAdmin)?firstName:''}, welcome back!
            </Typography>
            {!isLeadAgent && (
 <IconButton color="inherit" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
 {/* <Badge badgeContent={4} color="secondary">
   <NotificationsIcon sx={{color:'#000000'}}/>
 </Badge> */}
  <Badge badgeContent={notifications.filter(notification => !notification.isRead).length} color="error">
 <NotificationsIcon sx={{color:'#000000'}}/>
 </Badge>
</IconButton>
            )}
           
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {notifications.length > 0 && notifications.map((notification) => (
                
                <MenuItem key={notification._id} onClick={()=>{handleNavigartion(pathOr('', ['policy','_id'], notification))}}>
                  <div className={getNotiType(notification.notification)} style={{display:'flex',width:'100%',padding:'10px'}}>
                    <div style={{alignSelf:'center'}}>
                      <img
                          src={"https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/"+getNotiType(notification.notification)+".png"}
                          alt={"logo"}
                          loading="lazy" style={{width:'25px',height:'25px',alignItems:'flex-start',marginRight:'20px',marginLeft:'10px'}}
                      />
                    </div>
                    <div style={{width:'100%',marginRight:'10px'}}>
                      <div className='notiText'>{notification.notification}</div>
                      <div className='notiDate'>{moment(notification.createdAt).format('D MMM h.mm A')}</div>
                    </div>
                  </div>
                </MenuItem>
               
                
              ))}
            </Menu>
            {(isBrieziAdmin || isLeadAdmin) && (
               <Button style={{marginLeft:20}} variant="outlined" color="success" onClick={() => {
        dispatch({
          type: LOGOUT
        })
        navigate('/login')
      }}>
  Log out
</Button>
            )}
            {/* <Typography color="#151515">Max Life</Typography> */}
           
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} style={{backgroundColor:'#0D974D'}}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              //justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <img
                        src={"https://briezi-static.s3.ap-south-1.amazonaws.com/app/v1/MaxLifeAssetLogoInside.png"}
                        alt={"logo"}
                        loading="lazy" style={{width:'140px',height:'40px',alignItems:'flex-start'}}
                    />
            <IconButton onClick={toggleDrawer} style={{marginLeft:40}}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav" style={{backgroundColor:'#0D974D', color:'#ffffff',flex:1}}>
            {isLeadAdmin && (
              adminmainListItems
            )}
            {isBrieziAdmin && (
              adminmainListItems
            )}
            {isLeadAgent && (
              agentmainListItems
            )}
            {!isLeadAdmin && !isBrieziAdmin && !isLeadAgent && (mainListItems) }
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {props.children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
export default Dashboard
