import { pathOr } from 'ramda'
import {USER_PREFERENCE_UPDATE } from '../actions/types'

const initialState = {
  isLoading: false,
  showIntro: true,
  biometrics: {
    isBiometrics: false,
    isTouchID: false,
    isFaceID: false,
    biometricsKey: null,
    touchIdKey: null,
    faceIdKey: null
  },
  isWhatsappOptin: false,
  profilePic: 'https://briezi-static.s3.ap-south-1.amazonaws.com/app/image_placeholder.png',
  referredBy: ''
}
const defaultDp = 'https://briezi-static.s3.ap-south-1.amazonaws.com/app/image_placeholder.png'
const userPreferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PREFERENCE_UPDATE:
      return Object.assign({}, state, {
        isWhatsappOptin: pathOr(false, ['isWhatsappOptin'], action.payload),
        profilePic: pathOr(defaultDp, ['profilePic'], action.payload)
      })
    default:
      return state
  }
}

export default userPreferenceReducer
