import React, { useEffect } from 'react'
import swal from 'sweetalert'
import { useDispatch, useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { getLead, deleteLead,getLoanLead } from '../../redux/actions/leadAction'
import DashboardContent from '../../components/dashboard/Dashboard'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DataGrid, GridRowsProp, GridColDef,GridToolbar  } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  }

  function QuickSearchToolbar(props) {
    
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          textAlign:'left'
        }}
      >
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{
            width: {
              xs: 1,
              sm: 'auto',
            },
            m: (theme) => theme.spacing(1, 0.5, 1.5),
            '& .MuiSvgIcon-root': {
              mr: 0.5,
            },
            '& .MuiInput-underline:before': {
              borderBottom: 1,
              borderColor: 'divider',
            },
          }}
        />
      </Box>
    );
  }
  
  QuickSearchToolbar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  };
const PF = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
      useEffect(() => {
        dispatch(getLead({optFor:"premiumFinance"}))
      }, [dispatch])
    const leads = useSelector(state => pathOr([], ['leads'], state.Lead))
    useEffect(() => {
        setRows(leads)
      }, [leads])
         const column: GridColDef[] = [
          { field: 'policyHolderName', headerName: 'Name', width: 200, description:'Customer full name' },
          { field: 'policyNumber', headerName: 'Policy Number', width: 150 },
          // { field: 'policyId', headerName: 'Policy Id', width: 350,hide: true,hideable:false },
          { field: 'planName', headerName: 'Plan Name', width: 350 },
          { field: 'savingType', headerName: 'Type', width: 100 },
          { field: 'currentStep', headerName: 'Current Step', width: 150 },
          // { field: 'optFor', headerName: 'Opt For', width: 180 },
          {
            field: 'policyId',
            type: 'actions',
            headerName: 'Action',
            renderCell: (params) => (
              <div style={{justifyContent:'center'}}>
{/*               
              {isLeadAdmin && params.row.status=='' && (
                <EditIcon style={{cursor:'pointer',marginRight:8}} onClick={()=>{navigate('/editlead/'+params.row.policyId)}}/>
              )}
              {isLeadAdmin && (
                <DeleteIcon style={{cursor:'pointer',marginRight:8}} onClick={()=>{deleteConfirmation(params.row.policyId)}}/>
              )} */}
              
              <VisibilityIcon style={{cursor:'pointer'}} onClick={()=>{navigate('/viewlead/'+params.row.policyId)}}/>
              </div>
            ),
          },
        ];
  
        const deleteConfirmation = (id) => {
          swal('Do you really want to delete this lead?', {
            buttons: {
              cancel: 'Cancel',
              confirm: {
                text: 'Delete',
                value: 'delete'
              }
            }
          })
            .then((value) => {
              switch (value) {
                case 'delete':
                  const status=dispatch(deleteLead({ leadId: id }))
                  if(status){
                  
                  setTimeout(()=>{
                    dispatch(getLead())
                  },1000)
                  }
                  break
                default:
                  break
              }
            })
        }
  
      const  data  ={rows:leads,columns:column};
  
      const [searchText, setSearchText] = React.useState('');
      const [rows, setRows] = React.useState(data.rows);
      const requestSearch = (searchValue) => {
          setSearchText(searchValue);
          const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
          const filteredRows = data.rows.filter((row) => {
            return Object.keys(row).some((field) => {
              return searchRegex.test(row[field].toString());
            });
          });
          setRows(filteredRows);
        };
        const [value, setValue] = React.useState(0);
        const handleChange = (event, newValue) => {
          setValue(newValue);
          let filter="";
          switch(newValue){
            case 0:
              filter="";
              break;
            case 1:
              filter="approved";
              break;
            case 2:
              filter="rejected";
              break;
          }
          dispatch(getLead({"optFor":'premiumFinance',"status":filter}))
        };
  return (
    <DashboardContent>
       
        <Paper style={{paddingBottom:'20px'}}>
            <React.Fragment>
                <Typography variant="h6" gutterBottom style={{paddingTop:'20px',paddingBottom:'10px',textAlign:'left',marginLeft:20}}>Premium Financing Data</Typography>
                <Divider sx={{ my: 1 }} />
                <Tabs value={value} onChange={handleChange} style={{marginLeft:20}} aria-label="basic tabs example">
                  <Tab label="All" />
                  <Tab label="Approved" />
                  <Tab label="Rejected" />
                </Tabs>
                <div style={{ height: 500, width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1,padding:20 }}>
                        <DataGrid rows={rows} columns={data.columns} 
                            disableSelectionOnClick
                            components={{ Toolbar: QuickSearchToolbar }}
                            componentsProps={{
                                toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(''),
                                },
                            }}
                            />
                        </div>
                    </div>
                </div>
                
                
            </React.Fragment>
        </Paper>

    </DashboardContent>
  );
}
export default PF
